<template>
  <section class="m-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col>
            <FormulateInput
              id="pay_box_statement-origins"
              v-model="filters.origins"
              :label="$t('Tipo')"
              type="vue-select"
              multiple
              :placeholder="$t('Selecione múltiplas opções')"
              :options="saleOriginOptions"
            />
          </b-col>
          <b-col>
            <FormulateInput
              id="pay_box_statement-payment_methods"
              v-model="filters.paymentMethods"
              :label="$t('Forma de pagamento')"
              type="vue-select"
              multiple
              :placeholder="$t('Selecione múltiplas opções')"
              :options="getPaymentMethodsInPdvOptions"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="payBoxSale"
        show-empty
        responsive
        striped
        class="bordered mb-3"
        :empty-text="getEmptyTableMessage($tc('PAY_BOX_SALE.STATEMENT_SALE.NAME'))"
        :fields="fields"
        :items="statements"
        no-local-sorting
        :tbody-tr-class="rowClass"
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(date)="row">
          {{ row.item.date | datetime }}
        </template>

        <template #cell(origin)="row">
          {{ getStatementOrigin(row.item) }}
        </template>

        <template #cell(value)="row">
          <span :class="row.item.operationType === 'Debit' ? 'text-danger' : 'text-success'">
            {{
              (row.item.operationType === 'Debit' ? row.item.value * -1 : row.item.value) | currency
            }}
          </span>
        </template>

        <template #cell(observation)="row">
          <template v-if="row.item.observation">
            {{ row.item.observation }} <br>
          </template>
          <template v-if="row.item.salePayment && row.item.salePayment.nsu">
            <b>NSU: </b> {{ row.item.salePayment.nsu || '-' }}
          </template>
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="buttons(row.item)"
            @print="() => onPrint(row.item)"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="4">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="4">
          <e-color-subtitled
            :colors="[{ backgroundColor: 'table-danger', title: $t('Cancelado') }]"
          />
        </b-col>
        <b-col cols="4">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="statement-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import { loadingOverlay, payBoxTypes } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EColorSubtitled from '@/views/components/EColorSubtitled.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BTable,
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EGridActions,
    EColorSubtitled,
  },

  mixins: [payBoxTypes, loadingOverlay],

  props: {},

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('pages/pdv', ['getPaymentMethodsInPdvOptions']),
    ...mapGetters('pages/pdv/statements', ['printAvailableOrigin']),
    ...mapState('pages/pdv/statements', {
      statements: 'statements',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    showActions() {
      return this.$route.name === 'pdv-pay-box-statement'
    },

    buttons() {
      return row =>
        [
          {
            icon: 'printer-fill',
            variant: 'primary',
            title: this.$t('Imprimir'),
            event: 'print',
            hide: !this.printAvailableOrigin.includes(row.origin),
          },
        ].filter(b => !b.hide)
    },
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: !this.showActions,
        },
        {
          label: this.$t('Data'),
          key: 'date',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethod.name',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: (val, index, item) => item.paymentMethod.name,
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'origin',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Venda'),
          key: 'salePayment.SaleId',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: (val, i, item) =>
            item?.salePayment?.saleId ? item?.salePayment?.saleId : '-',
          sortable: true,
        },
        {
          label: this.$t('Operador'),
          key: 'user.name',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '300px' },
          formatter: (val, i, item) => item.user.name,
          sortable: true,
        },
        {
          label: this.$t('Observação'),
          key: 'observation',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          sortable: true,
        },
      ].filter(a => !a.hide)
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'filters.all': function (val) {
      console.log('check', val)
      // TODO se val === all selecionar todos os checkbox
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/payBoxPrint', {
      stPrintBleeding: 'printBleeding',
      stPrintOpenCashBook: 'printOpenCashBook',
    }),
    ...mapActions('pages/pdv/payBoxAccountsPayable', {
      stPrintAccountPayableReceipt: 'printAccountPayableReceipt',
    }),
    ...mapActions('pages/sale/order', {
      stPrintOrder: 'printOrder',
    }),
    ...mapActions('pages/pdv/statements', [
      'fetchStatements',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStatements()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    getStatementOrigin(item) {
      const bleedingAction = () => {
        if (item.operationType === this.operationTypeEnum.CREDIT)
          return this.$t('Sangria (estorno)')

        return this.$t('Sangria')
      }

      const saleAction = () => {
        if (item.salePayment.canceled) return this.$t('Venda (Cancelada)')
        return this.$t('Venda')
      }

      const actions = {
        [this.saleOriginEnum.BLEEDING]: bleedingAction,
        [this.saleOriginEnum.SALE]: saleAction,
      }

      if (actions[item.origin]) return actions[item.origin]()
      if (this.saleOriginEnumLabel[item.origin]) return this.saleOriginEnumLabel[item.origin]

      return this.$t('Outros')
    },

    async onPrint(item) {
      try {
        this.showLoadingOverlay(this.$t('Imprimindo'))

        // TODO reimpressão de pagamentos da venda...
        switch (item.origin) {
          case this.saleOriginEnum.BLEEDING: {
            await this.stPrintBleeding({ id: item.id })
            break
          }
          case this.saleOriginEnum.OPENING: {
            await this.stPrintOpenCashBook({ id: item.id })
            break
          }
          case this.saleOriginEnum.ACCOUNTS_PAYABLE: {
            await this.printAccountPayable(item)
            break
          }
          case this.saleOriginEnum.RETURN_SALE: {
            await this.printSaleReturn(item)
            break
          }
          default: {
            this.showWarning({
              message: this.$t('Reimpressão não está disponível para este tipo.'),
            })
            break
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async printAccountPayable(item) {
      if (!item.accountsPayableId) {
        this.showWarning({ message: this.$t('Não foi possível buscar a despesa.') })
        return
      }

      await this.stPrintAccountPayableReceipt({
        accountPayableId: item.accountsPayableId,
      })
    },

    async printSaleReturn(item) {
      if (!item.saleId) {
        this.showWarning({ message: this.$t('Não foi possível buscar a devolução.') })
        return
      }

      const { data: orderPrint } = await this.$http.get(`/api/sales/${item.saleId}`)
      await this.stPrintOrder({ orderPrint, template: 'SALE_RETURN' })
    },

    rowClass(item) {
      if (item?.active === false) return 'table-danger'
      return ''
    },

    filterData() {
      // this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped></style>
